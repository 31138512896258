@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import "_reset";


.status-page {
  margin: 0 auto;
  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #495057;
    @media screen and (max-width: 760px) {
      font-size: 26px;
      margin-bottom: 11px;
    }
  }
}
.ss-head {
/*   background: #eceff1; */
/*   overflow: hidden; */
  >div {
    max-width: 1200px;
    padding: 30px 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 760px) {
      padding: 20px 20px 24px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.next-schedule {
  border: 1px solid #F8DD91;
  background: white;
  border-radius: 8px;
  padding: 22px 65px 10px;
  /*padding: 20px 30px;*/
  font-size: 16px;
  box-shadow: 0px 22px 44px -20px rgba(0,0,0,.2);
  position: relative;
  overflow: hidden;
  color: #495057;
  width: 100%;
  @media screen and (max-width: 760px) {
    box-sizing: border-box;
  }
  max-width: 350px;
  &::after {
    content: "";
    position: absolute;
    height: 7px;
    left: 0;
    top: 0;
    right: 0;
    background: #F8DD91;
  }
  &::before {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    left: 20px;
    top: calc(50% - 9px);
    background: url("../img/info.svg") no-repeat 50% 50% / contain;
  }
  label {
    display: block;
    font-size: 14px;
    @media screen and (max-width: 760px) {
      font-size: 12px;
    }
  }
  p {
    display: block;
    font-size: 18px;
    font-weight: bold;
    @media screen and (max-width: 760px) {
      font-size: 14px;
    }
  }
}
.status-list {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto 0;
  >ul {
    >li {
      margin-bottom: 20px;
      box-shadow: 0px 22px 44px -16px rgba(0,0,0,.2);
      overflow: hidden;
      border: 1px solid #99AABB;
      border-radius: 8px;
      background: white;
      >div {
        background: #eceff1;
        cursor: pointer;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        color: #495057;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #99AABB;
        border-color: transparent;
        &::before, &::after {
          position: absolute;
          width: 12px;
          height: 2px;
          content: "";
          background: #495057;
          top: calc(50% - 1px);
          right: 24px;
          transform-origin: 50% 50%;
          transition: .3s;
          transform: rotate(0deg);
        }
        &::after {
          transform: rotate(-90deg);
        }
      }
      &.opened {
        >div {
          border-color: #99AABB;
          &::before, &::after {
            transform: rotate(-180deg);
          }
        }
      }
      >ul {
        color: #495057;
        padding: 15px 0;
        >li {
          margin-left: 15px;
          margin-right: 15px;
          padding: 0px 0;
          display: flex;
          margin-top: 4px;
          align-items: center;
          @media screen and (max-width: 760px) {
            flex-wrap: wrap;
            align-items: flex-start;
            border-bottom: 1px solid #99AABB;
            margin: 0 0 8px;
            padding: 4px 15px 4px;
            &:last-of-type {
              border-bottom: 0;
            }
          }
          >div {
            padding: 4px 0;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 20px;
            max-height: 38px;
            overflow: hidden;
            @media screen and (max-width: 760px) {
              max-height: 100%;
            }
          }
          >div:nth-of-type(1) {
            width: calc(20% - 14px);
            margin-right: 14px;
            font-weight: bold;
            @media screen and (max-width: 760px) {
              order: 1;
              width: calc(60% - 10px);
              margin-right: 10px;
            }
          }
          >div:nth-of-type(2) {
            width: calc(55% - 14px);
            margin-right: 14px;
            @media screen and (max-width: 760px) {
              margin-right: 10px;
              width: calc(60% - 10px);
              order: 3;
            }
          }
          >div:nth-of-type(3) {
            width: calc(12% - 14px);
            margin-right: 14px;
            @media screen and (max-width: 760px) {
              order: 2;
              width: 40%;
              margin-right: 0;
            }
          }
          >div:nth-of-type(4) {
            width: 13%;
            @media screen and (max-width: 760px) {
              order: 4;
              width: 40%;
            }
          }
        }
      }
    }
  }
  .color {
    height: 22px;
    border-radius: 4px;
    &.green {
      background: #7EEECE;
    }
    &.yellow {
      background: #fbecbe;
    }
    &.red {
      background: #f9a0a0;
    }
    &.black {
      background: #ccd5dd;
    }
  }
}
