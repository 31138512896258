.not-found-box {
  margin: 30px auto;
  background: url("../img/warning.svg") no-repeat 100% 100% white;
  width: calc(100% - 20px);
  max-width: 800px;
  box-sizing: border-box;
  border: 1px solid #99AABB;
  border-radius: 16px;
  padding: 12px;
  text-align: center;
  box-shadow: 0 10px 14px rgba(41, 40, 42, 0.1);
  .nlogo {
    margin: 50px 0;
    display: none;
    img {
      display: block;
      max-width: 150px;
      margin: 18px auto;
    }
    >div {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #41566C;
    }
  }
  .m404 {
    font-size: 150px;
    font-weight: 900;
    margin-top: 75px;
    margin-bottom: 18px;
    span {
      background: linear-gradient(to right, #008A00, #00B100);
      display: inline-block;
      background-clip: text;
      color: transparent;
    }
  }
  .m404add {
    color: #41566C;
    font-size: 27px;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 75px;
  }
  .goback {
    margin-bottom: 90px;
    margin-top: 75px;
    a {
      height: 46px;
      border-radius: 28px;
      width: 184px;
      display: block;
      margin: 0 auto;
      box-shadow: 0px 3px 6px rgba(97, 111, 123, 0.3);
      color: white;
      background: linear-gradient(to right, #008A00, #00B100);
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        display: block;
        width: 16px;
        height: 16px;
        background: url("../img/monbiback.svg") no-repeat 50% 50% / contain;
        margin-right: 10px;
        content: "";
      }
    }
  }
}
.fullh .not-found-box .nlogo {
  display: block;
}
