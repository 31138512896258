.flex {
  display: flex;
  .half {
    width: 50%;
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.mr-5 {
  margin-right: 5px;
}

.plain-content {
  p {
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul {
    padding-left: 20px;
    li {
      list-style: disc;
    }
    margin-bottom: 1em;
  }
  ol {
    padding-left: 20px;
    li {
      list-style: decimal;
    }
    margin-bottom: 1em;
  }
}


.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}