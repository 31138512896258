$--color-primary: #13ce67;
$--font-path: '../fonts';

@import '~element-ui/packages/theme-chalk/src/index';

.el-drawer__wrapper {
  .el-drawer__header {
    font-size: 20px;
  }

  .el-drawer__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 76px);
    .content {
      height: 100%;
      padding: 20px;
      overflow-y: auto;
      .el-form {
        flex-grow: 1;
      }
    }
  }

  .drawer__footer {
    display: flex;
    padding-top: 20px;
    padding: 20px;
    .button {
      width: 100%;
    }
  }
}