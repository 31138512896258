.mnb-field {
  margin-bottom: 15px;
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #768594;
  }
  .mnb-input {
    outline: none;
    background: white;
    border: 1px solid #99aabb;
    height: 30px;
    box-sizing: border-box;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bold;
    color: #63717d;
    width: 100%;
    max-width: unset;
  }
  textarea.mnb-input {
    height: 130px;
  }
  .complex-field.mnb-input {
    display: flex;
    align-items: center;
    input {
      flex-grow: 1;
      border: 0px none;
      -webkit-appearance: none;
      max-width: unset;
      outline: none;
    }
  }
}
.mnb-row {
  gap: 8px;
  display: flex;
  >div {
    width: 0;
    flex-grow: 1;
  }
}

.multi-dropdown {

  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: white;
  border: 1px solid #bec9d4;
  line-height: 46px;
  padding: 0 12px;
  font-size: 15px;
  color: #41566c;
  font-weight: bold;
  outline: none;
  position: relative;
  .value {
    cursor: default;
    display: block;
    box-sizing: border-box;
    padding: 2px 4px;
    position: relative;
    height: 46px;
    line-height: 42px;
    white-space: nowrap;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: top;
      background-image: url("../img/arrowd1.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  >ul {
    line-height: 24px;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: -1px;
    border: 1px solid #ccc;
    background: white;
    min-width: 280px;
    li label {
      padding: 2px 4px;
      display: flex;
      align-items: center;
      input {
        left: 0;
        top: 0;
        height: 20px;
      }
      input + span {
        width: 0;
      }
      input + span + span {
        margin-left: 5px;
        width: 0;
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    max-height: 300px;
    overflow: auto;
  }
  &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.content-filters .multi-dropdown {
  display: block;
  font-size: 13px;
  font-weight: bold;
  color: #63717d;
  padding: 0 28px 0 8px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #99aabb;
  border-radius: 6px;
  height: 29px;
  appearance: none;
  background-color: #fff;
  background-image: url("../img/arrowd1.svg");
  background-position: right 0.7em top 50%, 0 0;
  background-repeat: no-repeat;
  background-size: 0.65em auto, 100%;
  max-width: 500px;
  .value {
    line-height: 27px;
    &::after {
      display: none;
    }
  }
  input {
    appearance: auto;
    -webkit-appearance: auto;
    width: 16px;
  }
}








.content-form.content-form-modern {
  .form-row {
    div:not(.date-picker__cell, .date-picker__row, .weekday__row, .date-picker__month-button-wrapper, .date-picker__year-button-wrapper ) {
      margin-bottom: 15px;

      label {
        text-align: left;
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 14px;
        color: #768594;
        overflow: visible;
        position: relative;
        top: 0;
        left: 0;
        line-height: auto;
        transition: none;
        padding: 0;
        z-index: 0;
        &::before {
          display: none;
        }
      }
      &.filled {
        label {
          font-size: 14px;
          top: 0;
          left: 0;
        }
      }
      &.disabled {
        label {
          color: #768594;
        }
      }
      &.focused {
        input, .select {
          border-color: #9ab;
        }
      }
      input:not([type=checkbox]):not([type=radio]), .select {
        outline: none;
        border: 1px solid #9ab;
        height: 30px;
        box-sizing: border-box;
        padding: 0 8px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 700;
        line-height: 28px;
        color: #63717d;
        width: 100%;
        max-width: unset;

        &.error {
          border-color: red;
        }
      }
      .select.disabled {
        opacity: 0.7 !important;
        border-color: rgba(118, 118, 118, 0.3);
      }
      textarea {
        outline: none;
        border: 1px solid #9ab;
        //height: 30px;
        box-sizing: border-box;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        color: #63717d;
        width: 100%;
        max-width: unset;
        &.error {
          border-color: red;
        }
      }
      .select {
        position: relative;
        margin: 0;
        select {
          -webkit-appearance: none;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 30px;
          opacity: 0;
          optgroup[label] {
            color: gray;
          }
          option {
            color: #000;
          }
        }
        &::before {
          content: "";
          width: 12px;
          height: 8px;
          position: absolute;
          top: calc(50% - 4px);
          right: 12px;
          background: url("../img/arrowd1.svg") no-repeat 50% 50%;
          background-size: contain;
        }
        &.disabled::before {
          opacity: .6;
        }
      }
      .error-message {
        display: block;
        padding: 0 5px;
        box-sizing: border-box;
        margin-top: 5px;
        color: red;
        span {
          display: block;
        }
      }
    }
  }
}
