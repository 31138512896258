.dropdown-button {
  display: inline-block;
  position: relative;
  button {
    outline: none;
  }
  .dropdown-options {
    position: absolute;
    top: 45px;
    right: 10px;
    background-color: #fff;
    z-index: 99;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    li {
      padding: 5px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      cursor: pointer;
      &:hover {
        color: #0480c6;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}



a.action-button,
button.action-button {
  display: inline-block;
  padding: 1px 10px;
  line-height: 26px;
  height: auto;
  vertical-align: middle;
  border-radius: 4px;
  min-width: 70px;
  text-align: center;
  color: white;
  border: none;
  outline: none;
  -webkit-appearance: none;
  background-color: var(--main-color);
  font-weight: bold;
  font-size: 12px;
  box-sizing: border-box;
  cursor: pointer;
  &.update {
    background-color: #ffa700;
  }
  &.remove {
    background-color: #d62b2b;
  }
  &.save {
    background-color: #67c23a;
  }
  &.cancel {
    background-color: #666;
  }
  &.neutral {
    color: #616f7b;
    background: none;
    border: 1px solid #616f7b;
    padding: 0px 9px;
  }
}
