#header {
  width: 100%;
  height: 80px;
  background-color: #fff;
  padding: 20px 40px 20px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .quick-action {
    margin-right: 150px;
    @media screen and (max-width: 1200px) {
      margin-right: 0px;
    }
    li {
      display: inline-block;
      line-height: 40px;
      font-size: 24px;
      padding: 0 5px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}

#nav {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-info {
    flex-grow: 1;
    .title {
      margin-bottom: 15px;
      h1 {
        margin: 0;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        margin-right: 15px;
        text-transform: uppercase;
      }
      h2 {
        margin: 0;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }
}
