.popup .pop-inner .pop-box.session-timeout {  
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  p {
    font-size: 16px;
  }
  .button {
    display: block;
    margin: 20px auto 0;
    width: 120px;
    color: #fff;
    background: #0480c6;
    border: 0;
    height: 36px;
    box-sizing: border-box;
    border-radius: 15px;
    font-size: 16px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
  }
}

